<template>
  <!--begin: Wizard Form-->
  <Form
    :form="form"
    :title="'Edit Jadwal'"
    :route="`api/schedules/${form.id}`"
    purpose="edit"
  />
  <!--end: Wizard Form-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/schedules/Form.vue";
import module from "@/core/modules/CrudModule.js";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    async get() {
      this.form = await module.get("api/schedules/" + this.$route.params.id);
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/schedules");
      } else {
        this.form["_method"] = "put";
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Jadwal", route: "/schedules" },
      { title: "Edit" },
    ]);

    this.get();
  },
};
</script>